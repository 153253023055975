

































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Icon, List, Image, Divider, Empty } from "vant";
import showProList from "@/components/showProList.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [List.name]: List,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    showProList,
  },
})
export default class CategoryList extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: () => {
      return -1;
    },
  })
  scrollTop!: number;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  categoryId!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  city!: string;
  @Prop({
    type: String,
    default: () => {
      return "BY_All";
    },
  })
  discountType!: "BY_All" | "BY_COUPON" | "BY_CULTURE";
  @Prop({
    type: Number,
    default: () => {
      return 0;
    },
  })
  endTimeStamp!: number;
  @Prop({
    type: Boolean,
    default: () => {
      return false;
    },
  })
  flagshipStore!: boolean;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  keyword!: string;
  @Prop({
    type: String,
    default: () => {
      return "BY_START_DATE";
    },
  })
  orderType!: "BY_START_DATE" | "BY_PRICE" | "BY_SHELVING_DATE";
  @Prop({
    type: String,
  })
  priceEnum!:
    | "PC_LESS_THAN_ONE_HUNDRED"
    | "PC_ONE_HUNDRED_TO_THREE_HUNDRED"
    | "PC_THREE_HUNDRED_TO_FIVE_HUNDRED"
    | "PC_FIVE_HUNDRED_TO_EIGHT_HUNDRED"
    | "PC_GREATER_THAN_EIGHT_HUNDRED"
    | "MOBILE_LESS_THAN_THREE_HUNDRED"
    | "MOBILE_GREATER_THAN_THREE_HUNDRED"
    | undefined;
  @Prop({
    type: String,
    default: () => {
      return "BY_All";
    },
  })
  showType!: "BY_All" | "BY_PLAT" | "BY_THEATER";
  @Prop({
    type: Number,
    default: () => {
      return 0;
    },
  })
  startTimeStamp!: number;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  subCategoryId!: string;
  @Prop({
    type: Boolean,
    default: () => {
      return false;
    },
  })
  tour!: boolean;

  loading = false;
  productList: good.RecommendProductDto[] = [];
  currentPage = 1; // 滚动分页，当前在第几页
  pageFinished = false; // 滚动分页，是否已经没有数据了

  refresh(): void {
    /**
     * 虽然这里已经能拿到最新的prop值
     * 但是新的值要到下一个 event loop 才能被 CategoryList 内的 Prop 接收到
     * 所以要加一个 $nextTick()
     */
    this.$nextTick(() => {
      this.productList = [];
      this.currentPage = 1;
      this.queryData();
    });
  }

  onLoad(): void {
    this.currentPage++;
    setTimeout(() => {
      this.queryData();
    }, 1000);
  }

  queryData(): void {
    let currentPage = this.currentPage;
    this.loading = true;
    let distributionChannelId = this.$route.query.distributionChannelId;
    let distributionSeriesId = this.$route.query.distributionSeriesId;
    if (distributionChannelId && distributionSeriesId) {
      this.$api.goodApi.product.searchDistributionProducts(
        {
          categoryId: this.categoryId,
          city: this.city,
          distributionId: String(distributionChannelId),
          seriesId: String(distributionSeriesId),
          endTimeStamp: this.endTimeStamp || undefined,
          flagshipStore: this.flagshipStore,
          keyword: this.keyword,
          orderType: this.orderType,
          page: currentPage,
          size: 20,
          startTimeStamp: this.startTimeStamp || undefined,
          subCategoryId: this.subCategoryId,
          source: "common",
        },
        ({ data }) => {
          this.loading = false;
          if (data.records) {
            let productList = this.productList || [];
            this.productList = productList.concat(data.records);
          }
          this.setPageFinished(currentPage, data);
        }
      );
    } else {
      this.$api.goodApi.product.searchProducts(
        {
          categoryId: this.categoryId,
          city: this.city,
          discountType: this.discountType,
          endTimeStamp: this.endTimeStamp || undefined,
          flagshipStore: this.flagshipStore,
          keyword: this.keyword,
          orderType: this.orderType,
          page: currentPage,
          priceEnum: this.priceEnum || undefined,
          showType: this.showType,
          size: 20,
          startTimeStamp: this.startTimeStamp || undefined,
          subCategoryId: this.subCategoryId,
          tour: this.tour,
        },
        ({ data }) => {
          this.loading = false;
          if (data.records) {
            let productList = this.productList || [];
            this.productList = productList.concat(data.records);
          }
          this.setPageFinished(currentPage, data);
        }
      );
    }
  }

  // 滚动分页，根据返回的数据设置【是否查询完成】的标志
  setPageFinished(currentPage: number, data: good.Page<unknown>): void {
    let pages = 0;
    // 终止加载的标志
    if (data) {
      pages = data.pages || 0;
    }
    this.pageFinished = currentPage >= pages;
  }
}
